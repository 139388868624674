import { closestIndexTo, differenceInDays, format, parse } from "date-fns";
import React, { useMemo, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import { BarChart, SampleTheme } from "./BarChart";
import { DateSlider } from "./DateSlider";
import { useQuotes } from "./reducers/useQuotes";
import { SymbolInput } from "./SymbolInput";
import { dateFormat, EnrichedQuote } from "./types";

const Main = () => {
  const startDate = useMemo(() => new Date(2015, 0, 1), []);
  const endDate = useMemo(() => new Date(), []);
  const [selected, setSelected] = useState<Date | undefined>(startDate);

  const devAPI = "http://127.0.0.1:8080";
  const publicAPI = "https://stocks.sourcediver.org";

  const [symbols, setSymbols] = useState<string[]>([
    "SEV",
    "ARVL",
    "MULN",
    "GOEV",
    "FFIE",
    "RIDE",
    "TSLA",
    "RIVN",
    "VWAPY",
  ]);

  const quoteQueries = useQuotes(publicAPI, symbols, startDate, endDate);
  const dataUpdated = Object.entries(quoteQueries).map(
    (v) => v[1].dataUpdatedAt
  );

  const quotes: Record<string, EnrichedQuote> = useMemo(() => {
    if (selected === undefined) {
      return {};
    }
    let update: Record<string, EnrichedQuote> = {};
    for (const query of Object.entries(quoteQueries)) {
      if (query[1].isFetched && query[1].isSuccess) {
        const data = query[1].data.data;
        const symbol = query[1].data.symbol;
        const marketCapSorted = data
          .map((v) => v.marketCap)
          .sort((a, b) => a - b);
        const closestIndex = closestIndexTo(
          selected,
          data.map((v) => parse(v.date, dateFormat, new Date()))
        );
        if (closestIndex === undefined) {
          continue;
        }
        const closestDate = parse(
          data[closestIndex].date,
          dateFormat,
          new Date()
        );
        if (differenceInDays(closestDate, selected) > 7) {
          continue;
        }
        update[symbol] = {
          ...data[closestIndex],
          marketCapMin: marketCapSorted[0],
          marketCapMax: marketCapSorted[marketCapSorted.length - 1],
        };
      }
    }
    return update;
  }, [selected, dataUpdated]);

  return (
    <div className="App">
      <SymbolInput
        symbols={symbols}
        onChange={(v) => setSymbols(v)}
        placeholder={"Type a symbol, then hit enter or ,"}
      />
      <h1>{selected ? format(selected, dateFormat) : "Select a date"}</h1>
      <div className="slider">
        <DateSlider
          startDate={startDate}
          endDate={endDate}
          onChange={(d) => setSelected(d)}
        />
      </div>
      <BarChart quotes={quotes} width={1024} height={768} theme={SampleTheme} />
      <p>
        Not financial advice 🧐 -
        <a href="https://sourcediver.org/static/imprint/">Imprint</a>
      </p>
    </div>
  );
};

const App = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Main />
    </QueryClientProvider>
  );
};

export default App;
