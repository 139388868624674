import * as d3 from "d3";
import React, { useEffect, useRef } from "react";
import { EnrichedQuote } from "./types";

interface BarChartProps {
  quotes: Record<string, EnrichedQuote>;
  height: number;
  width: number;
  theme: Theme;
}

type Theme = {
  primary: string;
  dark: string;
  highlight: string;
  highlight2: string;
  background: string;
  background2: string;
};

export const SampleTheme: Theme = {
  primary: "#01b8aa",
  dark: "#28383c",
  highlight: "#fd625e",
  highlight2: "#f2c80f",
  background: "#5f6b6d",
  background2: "#8ad4eb",
};

export const BarChart = (props: BarChartProps) => {
  const d3Container = useRef(null);
  const maxMarketCap = Object.entries(props.quotes)
    .map((x) => x[1].marketCapMax)
    .sort((a, b) => b - a)[0];

  const dataset = Object.entries(props.quotes)
    .map(([key, value]) => {
      return {
        symbol: key,
        ...value,
      };
    })
    .sort((a, b) => a.marketCapMax - b.marketCapMax);

  useEffect(() => {
    const margin = 150;
    const width = props.width - margin * 2;
    const height = props.height - margin * 2;

    if (d3Container.current) {
      const svg = d3.select(d3Container.current);
      svg.selectAll("g").remove();
      svg.selectAll("rect").remove();
      svg
        .append("rect")
        .attr("width", "100%")
        .attr("height", "100%")
        .attr("fill", props.theme.background);

      const chart = svg
        .append("g")
        .attr("transform", `translate(${margin}, ${margin})`);

      const yScale = d3
        .scaleLinear()
        .range([height, 0])
        .domain([0, maxMarketCap]);
      chart.append("g").call(d3.axisLeft(yScale));

      const xScale = d3
        .scaleBand()
        .range([0, width])
        .domain(dataset.map((s) => s.symbol))
        .padding(0.2);

      chart
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(xScale));

      chart
        .selectAll()
        .data(dataset)
        .enter()
        .append("rect")
        .attr("x", (node) => xScale(node.symbol) || 0)
        .attr("y", (node, i) => yScale(node.marketCap))
        .attr("height", (node) => height - yScale(node.marketCap))
        .attr("width", xScale.bandwidth())
        .style("stroke", props.theme.background2)
        .style("fill", props.theme.background2);

      chart
        .selectAll()
        .data(dataset)
        .enter()
        .append("rect")
        .attr("x", (node) => xScale(node.symbol) || 0)
        .attr("y", (node, i) => yScale(node.marketCapMax))
        .attr("height", 1)
        .attr("width", xScale.bandwidth())
        .style("stroke", props.theme.highlight)
        .style("fill", props.theme.highlight);
    }
  }, [
    d3Container,
    maxMarketCap,
    dataset,
    props.theme,
    props.width,
    props.height,
  ]);

  return <svg width={props.width} height={props.height} ref={d3Container} />;
};
