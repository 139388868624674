import { addDays, differenceInCalendarDays, format } from "date-fns";
import React from "react";
import TooltipSlider from "./TooltipSlider";

interface DateSliderProps {
  startDate: Date;
  endDate: Date;
  onChange: (selected: Date) => void;
}

export const DateSlider = (props: DateSliderProps) => {
  const calendarDaysCount = differenceInCalendarDays(
    props.endDate,
    props.startDate
  );
  const tipFormatter = (value: number | null) => {
    if (value == null) {
      return "";
    }
    const added = addDays(props.startDate, value);
    return format(added, "yyyy-MM-dd");
  };

  return (
    <TooltipSlider
      tipFormatter={tipFormatter}
      tipProps={{ visible: true }}
      min={0}
      max={calendarDaysCount}
      onChange={(value) => {
        props.onChange(addDays(props.startDate, value as number));
      }}
    />
  );
};
