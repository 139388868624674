import format from "date-fns/format";
import { useQueries } from "react-query";
import { dateFormat, Quote } from "../types";

const query = `
    query GetQuote($symbol: String!, $startDateISO: String!, $endDateISO: String) {
      	quote(symbol: $symbol, startDateISO: $startDateISO, endDateISO: $endDateISO) {
    	    date,
    	    close,
    	    marketCap
  	    }    
    }
`;

const fetchQuoteBySymbol = async (
  host: string,
  symbol: string,
  startDate: string,
  endDate: string
): Promise<Quote[]> => {
  const response = await fetch(`${host}/graphql`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: query,
      variables: {
        symbol: symbol,
        startDateISO: startDate,
        endDateISO: endDate,
      },
    }),
  });
  if (!response.ok) {
    return Promise.reject(new Error("response not ok"));
  }
  const resJson = await response.json();
  return resJson["data"]["quote"] as Quote[];
};

export const useQuotes = (
  host: string,
  symbols: string[],
  startDate: Date,
  endDate: Date
) => {
  const startDateFormatted = format(startDate, dateFormat);
  const endDateFormatted = format(endDate, dateFormat);

  const quoteQueries = useQueries(
    symbols.map((symbol) => {
      return {
        queryKey: ["symbol", symbol, startDateFormatted, endDateFormatted],
        queryFn: async () => {
          return {
            symbol: symbol,
            data: await fetchQuoteBySymbol(
              host,
              symbol,
              startDateFormatted,
              endDateFormatted
            ),
          };
        },
      };
    })
  );

  return quoteQueries;
};
