import React, { useEffect, useState } from "react";

import "./SymbolInput.css";

interface SymbolInputProps {
  symbols: string[];
  onChange: (symbols: string[]) => void;
  placeholder?: string;
}

export const SymbolInput = (props: SymbolInputProps) => {
  const [input, setInput] = useState("");
  const [symbols, setSymbols] = useState<string[]>(props.symbols);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (key === "," || key === "Enter") {
      if (trimmedInput.length && !symbols.includes(trimmedInput)) {
        e.preventDefault();
        setSymbols((t) => [...t, trimmedInput]);
      }
      setInput("");
    }
  };

  const deleteTag = (index: number) => {
    setSymbols((t) => t.filter((tag, i) => i !== index));
  };

  const tagElements = symbols.map((tag, index) => (
    <li key={tag}>
      {tag}
      <button onClick={() => deleteTag(index)}>✕</button>
    </li>
  ));

  useEffect(() => {
    props.onChange(symbols);
  }, [symbols, props.onChange]);

  return (
    <ul>
      {tagElements}
      <input
        value={input}
        onKeyDown={onKeyDown}
        onChange={onChange}
        placeholder={props.placeholder}
      ></input>
    </ul>
  );
};
