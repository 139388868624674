export const dateFormat = "yyyy-MM-dd";
export type Quote = {
  date: string;
  close: number;
  marketCap: number;
};

export type EnrichedQuote = Quote & {
  marketCapMin: number;
  marketCapMax: number;
};
